















import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'
import InstructorsData from '~/components/instructor/instructors-data'
import {Instructor} from '~/components/data-class/data-class'
import {VAutocomplete} from 'vuetify/lib'

@Component({
    components: {
        VAutocomplete
    }
})
export default class TutorSelect extends Vue {
    @Prop({default: ''}) value!: string

    //  DATA
    instructors: Instructor[] = []

    get localValue() {
        return this.instructors.find(item => item.member_id === this.value)
    }

    set localValue(model: any) {
        this.$emit('input', _.isNil(model) ? '' : model.member_id)
    }

    get options() {
        return this.instructors
    }

    async created() {
        // GET tutors
        await InstructorsData.update()
        this.instructors = InstructorsData.instructors
    }

    formatTutorOption(tutor) {
        return `[${tutor._id}] ${tutor.display_name}`
    }
}
