




















import {CourseType} from '~/components/data-class/data-class'
import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {VAutocomplete} from 'vuetify/lib'

@Component({
    components: {
        VAutocomplete
    }
})
export default class CourseTypeSelect extends Vue {
    @Prop({default: ''}) value!: string
    @Prop({default: false}) autoSelectFirst: boolean
    @Prop({default: true}) clearable: boolean
    @Prop({default: false}) prependIcon: boolean
    @Prop({default: false}) dense: boolean
    @Prop({default: false}) solo: boolean
    @Prop({default: false}) hideDetails: boolean
    @Prop({default: false}) readonly: boolean

    @Prop({default: () => []}) hideOption: CourseType[]

    courseType: any[] = [
        {value: CourseType.LIVE, title: 'Live Course'},
        {value: CourseType.ONLINE, title: 'Online Course'},
        {value: CourseType.TRIAL, title: 'Trial Course'},
    ]

    get localValue() {
        return this.value
    }

    set localValue(type: any) {
        this.$emit('input', _.isNil(type) ? '' : type.value)
    }

    get options() {
        return _.filter(this.courseType, item => !this.hideOption.includes(item.value))
    }
}
