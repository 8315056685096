














import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {courseSubjects} from '@afterschool.dev/as-data-admin'
import {VAutocomplete} from 'vuetify/lib'

@Component({
    components: {
        VAutocomplete
    }
})
export default class SubjectSelect extends Vue {
    @Prop({default: ''}) value!: string


    get localValue() {
        return this.value
    }

    set localValue(subject: any) {
        this.$emit('input', _.isNil(subject) ? '' : subject)
    }

    get options() {
        return courseSubjects
    }
}
