

















import CourseData from '~/components/course/course-data'
import {Course} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import _ from 'lodash'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {VAutocomplete} from 'vuetify/lib'

@Component({
    components: {
        VAutocomplete
    }
})
export default class CourseSelect extends Vue {
    @Prop({default: ''}) value!: string

    //  UI DATA
    isLoading: boolean = false
    search: string = ''

    //  DATA
    courses: Course[] = []

    get localValue() {
        return CourseData.courseDict[this.value]
    }

    set localValue(course: any) {
        if (!_.isNil(course) && course._id !== this.value) {
            this.$emit('input', course._id)
        } else {
            this.$emit('input', '')
        }
    }

    get options() {
        return this.courses
    }

    created() {
        this.courses = _.values(CourseData.courseDict)
    }

    formatCourseOption(course) {
        return `[${course._id}] ${course.title}`
    }

    @Watch('search')
    watchSearch(newVal, oldVal) {
        if (newVal !== oldVal && !!newVal) {
            this.courses = []
            const q: any = {
                keyword: newVal,
                status: 'published,pending,preordering,private,hidden',
                limit: 0
            }

            this.isLoading = true

            createRequest('courses/admin', 'get', q).send()
                .then(async res => {
                    this.courses = res.data.courses
                    for (const course of this.courses) {
                        await CourseData.updateCourse(course._id, course)
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
