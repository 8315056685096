






































































































































import PageHeader from '~/components/app/page-header.vue'
import CourseSelect from '~/components/autocomplete/course-select.vue'
import CourseTypeSelect from '~/components/autocomplete/course-type-select.vue'
import SubjectSelect from '~/components/autocomplete/subject-select.vue'
import TutorSelect from '~/components/autocomplete/tutor-select.vue'
import {CourseSeries} from '~/components/data-class/data-class'
import InstructorsData from '~/components/instructor/instructors-data'
import SeriesData from '~/components/series/series-data'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {createRequest} from '~/utils/network-request'
import {ReportPermission} from '~/utils/permissions'
import _ from 'lodash'
import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'
import {VChip, VDataFooter, VDataTable, VDivider, VPagination, VSwitch, VTab, VTabs, VTextField} from 'vuetify/lib'

@Component({
    components: {
        PageHeader,
        VTab,
        VTabs,
        VChip,
        VSwitch,
        VDivider,
        VTextField,
        VDataTable,
        VDataFooter,
        CourseSelect,
        SubjectSelect,
        TutorSelect,
        CourseTypeSelect,
        VPagination
    },
    metaInfo() {
        return {
            title: 'Course Performance'
        }
    }
})
export default class CourseReport extends mixins(DatePickerShortcutMixin) {
    static permission = ReportPermission.CourseReport

    //  META DATA


    //  UI DATA
    currTab: number = 0

    selDateRange: number[] = []

    categories: string[] = []
    chartType: string = 'bar'

    selCourse: string = ''
    selSubject: string = ''
    selTutor: string = ''
    selCourseType: number = -1


    isLoading: boolean = false
    page: number = 1
    pageCount: number = 0
    tableHeaders = [
        {text: 'ID', value: '_id', width: 80, sortable: false},
        {text: 'Course', value: 'title', sortable: false},
        {text: 'Series', value: 'series_id', sortable: false},
        {text: 'Tutor', value: 'display_name', width: 200, sortable: false},
        {text: 'Type', value: 'type', width: 160, sortable: false},
    ]
    tableOption: any = {
        page: 1,
        itemsPerPage: 20
    }

    //  DATA
    seriesDict: { [key: string]: CourseSeries } = {}

    students: any[] = []
    revenue: any[] = []

    totalCourseStuRecord: number = 0
    courseStuRecord: any[] = []

    get pickerOptions(): { shortcuts: Array<{ text: string, onClick: {} }> } {
        return {
            shortcuts: this.datePickerShortcuts
        }
    }

    get chartConfig() {
        return {
            chart: {
                type: this.chartType,
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: this.categories
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => this.currTab === 1 ? '' : 'Student',
                    },
                    formatter: (value) => this.currTab === 1 ? '$' + value : value
                },
            }
        }
    }

    get sourceData() {
        this.chartType = 'bar'

        let data: any = []

        let targetData: any[] = []
        switch (this.currTab) {
            case 0:
                targetData = this.students
                break
            case 1:
                targetData = this.revenue
                break
        }

        this.categories = _.map(targetData, 'tag')

        return [{
            data: _.map(targetData, 'value')
        }]
    }

    async created() {
        const start = moment().startOf('month').valueOf()
        const end = moment().endOf('month').valueOf()
        this.selDateRange = [start, end]
        await this.fetchSeriesDashboard(start, end)
        await this.fetchCourseStuRecord()

        await SeriesData.update()
        this.seriesDict = SeriesData.seriesDict
    }

    async fetchSeriesDashboard(start, end) {
        const res = await createRequest('/dashboard/courses/top', 'get', {start: start, end: end}).send()

        this.students = res.data.students
        this.revenue = res.data.revenue
    }

    datePickerChanged() {
        const start = moment(this.selDateRange[0]).valueOf()
        const end = moment(this.selDateRange[1]).valueOf()
        this.fetchSeriesDashboard(start, end)
    }

    clickApplyFilter() {
        this.fetchCourseStuRecord()
    }

    clickQuickSelTutor(memberId) {
        this.selTutor = memberId
        this.clickApplyFilter()
    }

    clickClearFilter() {
        this.selCourse = ''
        this.selSubject = ''
        this.selTutor = ''
        this.selCourseType = -1
        this.fetchCourseStuRecord()
    }

    @Watch('tableOption', {deep: true})
    watchTableOption(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
            this.fetchCourseStuRecord()
        }
    }

    async fetchCourseStuRecord() {
        const payload: any = {}

        if (!!this.selCourse) {
            payload.keyword = this.selCourse
        }

        if (!!this.selSubject) {
            payload.subject = this.selSubject
        }

        if (!!this.selTutor) {
            payload.tutor = this.selTutor
        }

        if (!!this.selCourseType && this.selCourseType !== -1) {
            payload.type = this.selCourseType
        }

        const {page, itemsPerPage} = this.tableOption
        payload.limit = itemsPerPage
        payload.skip = (page - 1) * itemsPerPage

        this.isLoading = true

        const res = await createRequest('/dashboard/courses', 'get', payload).send()

        this.totalCourseStuRecord = res.data.count || 0
        this.courseStuRecord = res.data.courses

        this.isLoading = false

        // /dashboard/series
    }
}
